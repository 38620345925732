// App.js
import React, { useEffect, useState,Suspense, lazy, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axiosInstance from './api/axiosInstance';
import ErrorBoundaryWithSnackbar from './components/ErrorBoundary';
import { SnackbarProvider, useSnackbar } from './components/SnackbarContext';
import Dashboard from './Dashboard';
import ContainerDashboard from './components/ContainerDashboard';
import PracticeManagement from './PracticeManagement';
import PracticeForm from './PracticeForm';
import LivePractice from './LivePractice';
import LivePracticeTable from './LivePracticeTable';
import Sidebar from './components/Sidebar';
import { Grid, Box } from '@mui/material';
import UserManagementPage from './pages/UserManagementPage';
import PlaybookPage from './pages/PlaybookPage';
import PlaybookTypesPage from './pages/PlaybookTypesPage';
import PlaybookTagsPage from './pages/PlaybookTagsPage';
import PlayDetailPage from './pages/PlayDetailPage';
import PlayTablePage from './pages/PlayTablePage';
import PeriodActivityTable from './components/EventDetails/PeriodActivityTable';
import TeamEventsPage from './pages/TeamEventsPage';
import PracticeDetailPage from './pages/PracticeDetailPage';
import ScriptPage from './pages/ScriptPage';

function App({ onLogout }) {
    const [selectedOrganisation, setSelectedOrganisation] = useState([]);
    const [organisations, setOrganisations] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [selectedOffensePlaybook, setSelectedOffensePlaybook] = useState([]);
    const [selectedDefensePlaybook, setSelectedDefensePlaybook] = useState([]);
    const [selectedSpecialPlaybook, setSelectedSpecialPlaybook] = useState([]);
   // const { showSnackbar } = useSnackbar();

    useEffect(() => {
        fetchOrganisations();
    }, []);

    const fetchOrganisations = async () => {
        try {
            const response = await axiosInstance.get('user_management/users/organisations/');
            setOrganisations(response.data);
            if (response.data.length > 0) {
                setSelectedOrganisation(response.data[0]); // Wähle das erste Team als ausgewähltes Team aus
                if (response.data[0].teams.length > 0) {
                    setSelectedTeam(response.data[0].teams[0]); // Wähle das erste Team als ausgewähltes Team aus
                } else {
                    setSelectedTeam(null);
                }
                // fetchTeams(response.data[0]);
            }
        } catch (error) {
            console.log('Fehler beim Abrufen der Organisationen: ' , error);
        }
    };
    
    const handleTeamSelect = (Organisation, Team) => {
        setSelectedOrganisation(Organisation);
        setSelectedTeam(Team);
    };

    const handleOffensePlaybookSelect = (OffensePlaybook) => {
        setSelectedOffensePlaybook(OffensePlaybook);
    }


    const handleDefensePlaybookSelect = (DefensePlaybook) => {
        setSelectedDefensePlaybook(DefensePlaybook);
    }
    
    const handleSpecialPlaybookSelect = (SpecialPlaybook) => {
        setSelectedSpecialPlaybook(SpecialPlaybook);
    }
    return (
        <SnackbarProvider>
            <ErrorBoundaryWithSnackbar>
                <Box sx={{ display: 'flex', minHeight: '100vh', backgroundColor: 'primary.main',  }}>
                    
                    {/* Sidebar Bereich */}
                    <Grid
                            item
                            sx={{
                                width: { xs: '60px', md: '250px' },
                                minHeight: '100vh',
                            }}
                        >
                            <Sidebar 
                                organisations={organisations}
                                selectedOrganisation={selectedOrganisation}
                                selectedTeam={selectedTeam}
                                onSelectTeam={handleTeamSelect}/>
                        
                        </Grid>
                        {/* Dashboard Bereich */}
                        <Grid 
                            item
                            sx={{
                                flexGrow: 1,
                                minHeight: '100vh',
                                padding: 2,
                                bgcolor: 'primary.main',
                            }}
                        >
                            <ContainerDashboard>
                                        <Routes>
                                            <Route 
                                            path="/" 
                                            element={<Dashboard selectedTeam={selectedTeam} />} 
                                            />
                                            <Route
                                            path="/user-management"
                                            element={<UserManagementPage organisationId={selectedOrganisation?.id} />}
                                            />
                                            <Route 
                                                path="/:unit/play/:playId"
                                                element={<PlayDetailPage organisationId={selectedOrganisation?.id}/>} />
                                            <Route 
                                                path="/plays/:unit"
                                                element={<PlayTablePage organisationId={selectedOrganisation?.id}/>} />
                                            <Route
                                                path="/playbooks/:unit"
                                                element={<PlaybookPage 
                                                            organisationId={selectedOrganisation?.id} 
                                                            teamId={selectedTeam?.id}
                                                            selectOffensePlaybook={handleOffensePlaybookSelect}
                                                            selectDefensePlaybook={handleDefensePlaybookSelect}
                                                            selectSpecialPlaybook={handleSpecialPlaybookSelect}
                                                            />}
                                                />
                                            <Route 
                                                path="/terminology/types/:unit"
                                                element={<PlaybookTypesPage organisationId={selectedOrganisation?.id}/>} />
                                            <Route 
                                                path="/terminology/:unit/tags/:type"
                                                element={<PlaybookTagsPage organisationId={selectedOrganisation?.id}/>} />
                                            <Route 
                                                path="/teams/:teamId/events" 
                                                element={<TeamEventsPage teamId={selectedTeam?.id} />} />
                                            <Route 
                                                path="/events/practice/:practiceId" 
                                                element={<PracticeDetailPage />} />
                                            <Route 
                                                path="/scripts/:scriptId/plays/" 
                                                element={<ScriptPage />} />
                    
                                            <Route path="/practices" element={<PracticeManagement organisationId={selectedOrganisation?.id} teamId={selectedTeam?.id} />} />
                                            <Route path="/practices/:practiceId" element={<LivePracticeTable />} />
                                            <Route path="/create_practice" element={<PracticeForm teamId={selectedTeam?.id} />} />
                                            <Route path="/practice/:practice_id/:playType" element={<LivePractice />} />
                                        </Routes>
                            </ContainerDashboard>
                        </Grid>
                </Box>
            </ErrorBoundaryWithSnackbar>
        </SnackbarProvider>
    );
}

export default App;
