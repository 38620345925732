// Sidebar.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import { Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, Box, Typography, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';  // Beispiel für Icon
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';  
import SettingsIcon from '@mui/icons-material/Settings';  // Beispiel für Icon
import { Link, useLocation, NavLink } from 'react-router-dom';
import OrganisationDropdown from './OrganisationDropdown';
import '../assets/scss/Sidebar.scss';

const Sidebar = ({ selectedOrganisation, selectedTeam, organisations, onSelectTeam, onLogout }) => {
    const [offenseTypes, setOffenseTypes] = useState([]);
    const [defenseTypes, setDefenseTypes] = useState([]);
    const [specialTypes, setSpecialTypes] = useState([]);
    const [menuItems, setMenuItems] = useState([]);

    const location = useLocation();
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        // Holen der PlaybookTypes für jede Einheit (Offense, Defense, Special)
        if (selectedOrganisation.id) {
            fetchPlaybookTypes('Offense', setOffenseTypes);
            fetchPlaybookTypes('Defense', setDefenseTypes);
            fetchPlaybookTypes('Special', setSpecialTypes);
        }
    }, [selectedOrganisation]);

    
    useEffect(() => {
      setMenuItems([
        {
          title: 'Team',
          subItems: [
            { title: 'Team Manager', path: '/user-management' },
            { title: 'Schedule', path: `/teams/${selectedTeam.id}/events`}
          ]
        },
        {
          title: 'Offense',
          subItems: [
            { title: 'Plays', path: '/plays/Offense' },
            {
              title: 'Playbooks',
              path: '/playbooks/Offense',
              childrens: offenseTypes.map((item) => ({
                name: item.name,
                id: item.id,
                path: `terminology/Offense/tags/${item.id}`, // Beispielpfad
              })),
            },
          ],
        },
        {
          title: 'Defense',
          subItems: [
            { title: 'Plays', path: '/plays/Defense' },
            {
              title: 'Playbooks',
              path: '/playbooks/Defense',
              childrens: defenseTypes.map((item) => ({
                name: item.name,
                id: item.id,
                path: `terminology/Defense/tags/${item.id}`, // Beispielpfad
              })),
            },
          ],
        },
        {
          title: 'Special Teams',
          subItems: [
            { title: 'Plays', path: '/plays/Special' },
            {
              title: 'Playbooks',
              path: '/playbooks/Special',
              childrens: specialTypes.map((item) => ({
                name: item.name,
                id: item.id,
                path: `terminology/Special/tags/${item.id}`, // Beispielpfad
              })),
            },
          ],
        },
      ]);
    }, [offenseTypes, defenseTypes, specialTypes]);

    const fetchPlaybookTypes = async (unit, setState) => {
        try {
        const response = await axiosInstance.get(`playbook_management/${selectedOrganisation.id}/menu/${unit}/playbook-types/`);
        setState(response.data);
        } catch (error) {
        console.error(`Error fetching ${unit} playbook types:`, error);
        }
    };


      return (
        <div style={{ width: 250, backgroundColor: 'primary.main', padding: '16px 0', borderRadius: '0 30px 30px 0' }}>
          <Typography variant="h5" sx={{ padding: '16px', color: 'white' }}>
            Football Tracker
          </Typography>

          <OrganisationDropdown
                    selectedOrganisation={selectedOrganisation}
                    selectedTeam={selectedTeam}
                    organisations={organisations}
                    onSelectTeam={onSelectTeam} />
          <Divider sx={{ marginBottom: '0.5rem' }} />
          
          {menuItems.map((item, index) => (
          item.subItems ? (
            <Accordion
              key={index}
              expanded={expanded === item.title}
              onChange={handleAccordionChange(item.title)}
              sx={{
                boxShadow: 'none',
                backgroundColor: 'primary.main',
                border: 'none',
                margin: '0',
                padding: '0',
                '&::before': { display: 'none' },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{
                  color: expanded === item.title ? 'primary.main' : 'background.default',
                  '&:hover': {
                    color: 'primary.main',
                  },
                }} />}
                sx={{
                  padding: '8px 16px',
                  color: expanded === item.title ? 'primary.main' : 'background.default',
                  backgroundColor: expanded === item.title ? 'background.default' : 'primary.main',
                  '&:hover': {
                    backgroundColor: 'background.default',
                    color: 'primary.main'
                  },
                  borderRadius: '15px',
                }}
              >
                {item.icon}
                <Typography sx={{ marginLeft: 2 }}>{item.title}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <List>
                  {item.subItems.map((subItem, idx) => (
                    <React.Fragment key={idx}>
                      <ListItem
                          component={NavLink}
                          to={subItem.path}                        
                          sx={{
                            padding: '8px 16px',
                            color: 'white',
                            '&.active': {
                              backgroundColor: 'background.default',
                              color: 'primary.main',
                              fontWeight: 'bold',
                            },
                            '&:hover': {
                              backgroundColor: 'background.default',
                              color: 'primary.main',
                            },
                          }}
                        >
                          {subItem.icon}
                          <Typography sx={{ marginLeft: 1 }}>{subItem.title}</Typography>
                      </ListItem>

                      {/* Render Childrens if exist */}
                      {subItem.childrens &&
                        subItem.childrens.map((children, childIdx) => (
                        <ListItem
                          key={childIdx}
                          component={NavLink}
                          to={children.path}
                          sx={{
                            padding: '8px 16px',
                            color: 'white',
                            '&.active': {
                              backgroundColor: 'background.default',
                              color: 'primary.main',
                              fontWeight: 'bold',
                            },
                            '&:hover': {
                              backgroundColor: 'background.default',
                              color: 'primary.main',
                            },
                          }}
                        >
                          {children.icon}
                          <Typography sx={{ marginLeft: 2 }}>{children.name}</Typography>
                        </ListItem>
                          
                        ))}
                    </React.Fragment>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ) : (
            <ListItem
              key={index}
              component={NavLink}
              to={item.path}
              sx={{
                padding: '8px 16px',
                color: 'white',
                '&.active': {
                  backgroundColor: 'background.default',
                  color: 'primary.main',
                  fontWeight: 'bold',
                },
                '&:hover': {
                  backgroundColor: 'background.default',
                  color: 'primary.main',
                },
              }}
            >
              {item.icon}
              <Typography sx={{ marginLeft: 1 }}>{item.title}</Typography>
            </ListItem>
          )
        ))}
        </div>
      );
};

export default Sidebar;
