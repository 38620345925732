import React, { createContext, useContext, useState } from 'react';

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
    const [snackbar, setSnackbar] = useState({ message: '', open: false });

    const showSnackbar = (message) => {
        setSnackbar({ message, open: true });
        setTimeout(() => setSnackbar({ message: '', open: false }), 3000); // Automatisches Schließen nach 3 Sekunden
    };

    return (
        <SnackbarContext.Provider value={{ showSnackbar }}>
            {children}
            {snackbar.open && (
                <div style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: '#333',
                    color: '#fff',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    zIndex: 1000,
                }}>
                    {snackbar.message}
                </div>
            )}
        </SnackbarContext.Provider>
    );
};
