// src/pages/PracticeDetailPage.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, IconButton, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axiosInstance from '../api/axiosInstance';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import { de } from 'date-fns/locale';
import { format, addMinutes, parse, isValid } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';


const PracticeDetailPage = () => {
  const { practiceId } = useParams();
  const [practice, setPractice] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataReady, setDataReady] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isAddingPeriod, setIsAddingPeriod] = useState(false);
  const [isInitialPeriodAdded, setIsInitialPeriodAdded] = useState(false); // Schutzflag

  useEffect( () => {
    if (practiceId) {
      fetchPractice();
    }
  }, [practiceId]);

  useEffect( () => {
    if (practice && practice.id && practiceId) {
      fetchPeriods();
    }
  }, [practice]);

  useEffect( () => {
    console.log(practice);
    if(practice.id) {
      console.log("length: " + practice.periods.length);
      if (periods.length === 0 && !isInitialPeriodAdded && practice.periods.length === 0) {
        console.log("Handle period 0");
        handleAddPeriod(0);
        setIsInitialPeriodAdded(true);
      } else {
        setIsInitialPeriodAdded(true);
      }
    }
  }, [ isInitialPeriodAdded, practice]);

  const fetchPractice = async() => {
    setLoading(true);
    try {
      console.log("fetch Practice");
      const response = await axiosInstance.get(`/practices/${practiceId}/`);
      setPractice(response.data);
    } catch (error) {
      console.error('Fehler beim Laden des Practice:', error);
    } finally {
      setLoading(false);
    }
  }

  const fetchPeriods = async () => {
    setLoading(true);
    try {
      if(practice != null) {
        const response = await axiosInstance.get(`/practices/${practiceId}/periods/`);
        console.log("Check length: " + response.data.length);
        if(response.data.length > 0) {
          setPeriods(response.data);
          setRows(response.data.map(period => ({  
            ...period,
            start_time: new Date(`1970-01-01T${period.start_time}`),
            end_time: new Date(`1970-01-01T${period.end_time}`)
          })));
          setIsAddingPeriod(true);
        } else {
          handleAddPeriod(0);
          setIsInitialPeriodAdded(true);
        }
      }
      
    } catch (error) {
      console.error('Fehler beim Laden der Perioden:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddPeriod = async (position) => {
    try {
      console.log("Add Period: " + practice.id);
      if(practice.id) {
        console.log("YES");
        const practiceDate = practice?.date ? new Date(practice.date) : new Date();
    
        if (!isValid(practiceDate)) {
          console.error("Ungültiges Datum für practice.date");
          return;
        }
        // Holt die end_time der vorherigen Periode oder setzt einen Standardwert, falls position 0 ist
        const previousEndTime = position > 0 
        ? periods[position - 1].end_time 
        : practice.date ? format(new Date(practice.date), 'HH:mm:ss') : '00:00:00';        // Startzeit der neuen Periode auf vorherige Endzeit setzen
        const startTime = parse(previousEndTime, 'HH:mm:ss', new Date());

        // Endzeit auf 5 Minuten nach der Startzeit setzen
        const endTime = addMinutes(startTime, 5);

        const newPeriod = {
          name: "",
          start_time: format(startTime, 'HH:mm:ss'), // Formatierte Startzeit
          end_time: format(endTime, 'HH:mm:ss'), 
          order: periods.length + 1,
          practice: practiceId,
          ol: "",
          rb: "",
          wr: "",
          qb: "",
          dl: "",
          lb: "",
          db: ""
        };
        console.log("New Period: " + newPeriod);
        const response = await axiosInstance.post(`/practices/${practiceId}/periods/`, newPeriod);
        const addedPeriod = response.data;
        // Aktualisiere Reihenfolge für alle Perioden
        const updatedPeriods = [
          ...periods.slice(0, position),
          addedPeriod,
          ...periods.slice(position),
        ].map((period, index) => ({
          ...period,
          order: index + 1,
        }));
        setPeriods(updatedPeriods);
        setRows(updatedPeriods);
        // Sende aktualisierte Reihenfolge zum Server
        await updateOrdersOnServer(updatedPeriods);
      }      
    } catch (error) {
      console.error("Fehler beim Hinzufügen der Periode:", error);
    }
  };

  const updateOrdersOnServer = async (updatedPeriods) => {
    try {
      const updatePromises = updatedPeriods.map(period =>
        axiosInstance.patch(`/practices/${practiceId}/periods/${period.id}/`, { order: period.order })
      );
      await Promise.all(updatePromises);
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Reihenfolge auf dem Server:', error);
    }
  };

  const handleDeletePeriod = async (periodId) => {
    try {
      // Löscht die spezifische Periode
      await axiosInstance.delete(`/practices/${practiceId}/periods/${periodId}/`);
      // Aktualisiere Reihenfolge
      const updatedPeriods = periods
        .filter(period => period.id !== periodId)
        .map((period, index) => ({
          ...period,
          order: index + 1,
        }));
      setPeriods(updatedPeriods);
      setRows(updatedPeriods);
      // Sende aktualisierte Reihenfolge zum Server
      await updateOrdersOnServer(updatedPeriods);
    } catch (error) {
      console.error('Fehler beim Löschen der Periode:', error);
    }
  };

  const handleCellEditCommit = async (params) => {
    const { id, field, value } = params;
    if(field != "start_time" && field != "end_time") {
      try {
        await axiosInstance.patch(`/practices/${practiceId}/periods/${id}/`, { [field]: value });
        fetchPeriods();
      } catch (error) {
        console.error("Fehler beim Bearbeiten der Periode:", error);
      }
    }
  };

  // useEffect to check if data is ready before displaying DataGrid
  useEffect(() => {
    if (!loading && periods.length > 0 && isInitialPeriodAdded) {
      setDataReady(true);
      const updatedRows = periods.map(period => ({
        id: period.id,
        order: period.order,
        start_time: period.start_time,// parse(period.start_time, 'HH:mm', new Date()),  /Parse Zeit
        end_time: period.end_time, //parse(period.end_time, 'HH:mm', new Date()),  
        general_description: period.general_description,
        ol: period.ol,
        rb: period.rb,
        wr: period.wr,
        qb: period.qp,
        dl: period.dl,
        lb: period.lb,
        db: period.db
      }));
      setRows(updatedRows);
      
      const updatedColumns = [
        { field: 'order', headerName: 'Periode', flex: 0.5 },
        {
          field: 'start_time',
          headerName: 'Von',
          flex: 1,
          editable: true,
          renderEditCell: (params) => (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
              <TimePicker
                ampm={false}  // 24-Stunden-Format
                views={['hours', 'minutes']}
                value={parse(params.value, 'HH:mm:ss', new Date())}
                onChange={(newValue) => handleTimeChange(params.row.id, 'start_time', newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          ),
        },
        {
          field: 'end_time',
          headerName: 'Bis',
          flex: 1,
          editable: true,
          renderEditCell: (params) => (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
              <TimePicker
                ampm={false}  // 24-Stunden-Format
                views={['hours', 'minutes']}
                value={parse(params.value, 'HH:mm:ss', new Date())}
                onChange={(newValue) => handleTimeChange(params.row.id, 'end_time', newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          ),
        },
        { field: 'general_description', headerName: 'Beschreibung', flex: 1.5, editable: true },
        { field: 'ol', headerName: 'OL', flex: 1, editable: true },
        { field: 'rb', headerName: 'RB', flex: 1, editable: true },
        { field: 'wr', headerName: 'WR', flex: 1, editable: true },
        { field: 'qb', headerName: 'QB', flex: 1, editable: true },
        { field: 'dl', headerName: 'DL', flex: 1, editable: true },
        { field: 'lb', headerName: 'LB', flex: 1, editable: true },
        { field: 'db', headerName: 'DB', flex: 1, editable: true },
        {
          field: 'actions',
          headerName: 'Aktionen',
          width: 150,
          renderCell: (params) => (
            <>
              <IconButton onClick={() => handleDeletePeriod(params.row.id)}>
                <DeleteIcon color="error" />
              </IconButton>
              <IconButton onClick={() => handleAddPeriod(params.row.order)}>
                <AddIcon color="primary" />
              </IconButton>
            </>
          ),
        },
        
      ];
      setColumns(updatedColumns);
    }
  }, [loading, periods, isInitialPeriodAdded]);
    // Event Handler für Inline-Bearbeitung mit processRowUpdate
    const processRowUpdate = async (newRow) => {
      const { id, start_time, end_time, ...restFields } = newRow;
  
      try {
        // Überprüfen, ob es nur normale Felder sind, dann nur restFields senden
        await axiosInstance.patch(`/practices/${practiceId}/periods/${id}/`, restFields);
        fetchPeriods(); 
        return newRow;  
      } catch (error) {
        console.error("Fehler beim Bearbeiten der Periode:", error);
        return { ...newRow, isError: true }; 
      }
    };
  
    const handleTimeChange = (id, field, value) => {
      const formattedTime = format(value, 'HH:mm:ss'); 
      setRows(prevRows =>
        prevRows.map(row =>
          row.id === id ? { ...row, [field]: formattedTime } : row
        )
      );
  
      // Server-Update für Zeitfelder
      axiosInstance
        .patch(`/practices/${practiceId}/periods/${id}/`, { [field]: `${formattedTime}:00` })
        .catch((error) => console.error("Fehler beim Bearbeiten der Zeit:", error));
    };
  

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" mb={2}>Training am {new Date(practice?.date).toLocaleDateString()}: {practice?.name}</Typography>

      <Button variant="contained" color="primary" onClick={() => handleAddPeriod(periods.length)} 
        sx={{ mb: 2 }}
        startIcon={<AddIcon />}>
        Neue Periode hinzufügen
      </Button>

      {dataReady ? (
        <Box sx={{ width: '100%', maxWidth: '80vw', overflowX: 'auto' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          
          pageSize={5}
          rowsPerPageOptions={[5]}
          processRowUpdate={processRowUpdate}
          disableSelectionOnClick
          autoHeight
        />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default PracticeDetailPage;