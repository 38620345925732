// axiosInstance.js
import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://football-tracker.de/api/',
    timeout: 10000,
  });

// Interceptor, um den Token hinzuzufügen
axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Token ${token}`;
      }
      return config;
    },
    (response) => {
      return response; // Erfolgreiche Antwort durchlassen
    },
    (error) => {
        if (error.response) {
            const statusCode = error.response.status;

            // Globale Behandlung bestimmter HTTP-Statuscodes
            if (statusCode === 401) {
                // Benutzer ist nicht authentifiziert
                alert('Your session has expired. Please log in again.');
                window.location.href = '/login'; // Weiterleitung zur Login-Seite
            } else if (statusCode === 403) {
                alert('You do not have permission to perform this action.');
            } else if (statusCode >= 500) {
                alert('A server error occurred. Please try again later.');
            }
        } else if (error.request) {
            // Keine Antwort vom Server (z. B. Netzwerkprobleme)
            alert('Network error: Please check your internet connection.');
        } else {
            // Andere Fehler
            alert('An unexpected error occurred: ' + error.message);
        }

        // Fehler weitergeben, falls spezifische Fehlerbehandlung erforderlich ist
        return Promise.reject(error);
    }
  );
  

export default axiosInstance;
