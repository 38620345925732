import React from 'react';
import { useSnackbar } from './SnackbarContext';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        const { showSnackbar } = this.props;
        console.error('Error caught by ErrorBoundary:', error, errorInfo);

        // Zeige eine Snackbar für den Fehler
        showSnackbar(`Error: ${error.message}`);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

const ErrorBoundaryWithSnackbar = (props) => {
    const { showSnackbar } = useSnackbar();

    return <ErrorBoundary {...props} showSnackbar={showSnackbar} />;
};

export default ErrorBoundaryWithSnackbar;
